import classNames from 'classnames';
import s from './Loader.module.scss';

interface LoaderProps {
    className?: string;
}

export const Loader = (props: LoaderProps) => {
    const { className } = props;

    return <span className={classNames(s.loader, {}, [className])} />;
};
