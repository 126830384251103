import { useEffect } from 'react';
import { isApiResponse } from '@/shared/lib/utils/typeChecker';
import { useAlert } from '@/shared/lib/hooks/useAlert';
import { codeList, CodeListI } from '@/shared/model/types/error';

export const useError = (error: any, resetCaptcha?: () => void) => {
    const onAlert = useAlert();
    useEffect(() => {
        if (error)
            if (isApiResponse(error)) {
                if (error.data.details.code === 101117) {
                    resetCaptcha && resetCaptcha();
                } else
                    onAlert(
                        codeList[error.data.details.code as keyof CodeListI],
                        true,
                    );
            } else onAlert('Произошла неизвестная ошибка', true);
    }, [error]);
};
