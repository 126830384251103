import classNames from 'classnames';
import s from './Input.module.scss';
import { ChangeEvent, ReactNode } from 'react';
import CheckIcon from '@/shared/assets/icons/check.svg';
import WarningIcon from '@/shared/assets/icons/warning.svg';
import { IMaskInput } from 'react-imask';
import { Tooltip } from '@/shared/ui/Tooltip/Tooltip';
import InfoIcon from '@/shared/assets/icons/info.svg';

export interface InputProps {
    label?: string;
    className?: string;
    placeholder?: string;
    error: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement> | string) => void;
    disabled?: boolean;
    isSubmitted?: boolean;
    baseAddon?: boolean;
    mask?: string;
    icon?: ReactNode;
    onFocus?: () => void;
    onBlur?: () => void;
    name?: string;
    tooltipValue?: string;
    tooltipClassName?: string;
}

export const Input = (props: InputProps) => {
    const {
        className,
        label,
        placeholder,
        error,
        value = '',
        onChange,
        disabled,
        isSubmitted,
        baseAddon = true,
        mask,
        icon,
        onFocus,
        onBlur,
        name,
        tooltipValue,
        tooltipClassName,
    } = props;

    const mods = {
        [s.disabled]: disabled,
        [s.isError]: !!error,
    };

    return (
        <div className={classNames(s.Input, mods, [className])}>
            {label && (
                <div className={s.labelWrapper}>
                    <label className={s.label} htmlFor={label}>
                        {label}
                    </label>
                    {tooltipValue && (
                        <Tooltip
                            value={tooltipValue}
                            className={tooltipClassName}
                        >
                            <InfoIcon />
                        </Tooltip>
                    )}
                </div>
            )}
            <div className={s.wrapper}>
                {mask ? (
                    <IMaskInput
                        className={classNames(
                            s.input,
                            { [s.baseAddon]: baseAddon },
                            [className],
                        )}
                        value={value}
                        overwrite={true}
                        mask={mask}
                        autofix={'pad'}
                        onAccept={(value) => onChange(value)}
                        placeholder={placeholder}
                        type={'text'}
                        disabled={disabled}
                        name={name}
                        id={label}
                    />
                ) : (
                    <input
                        className={classNames(
                            s.input,
                            { [s.baseAddon]: baseAddon },
                            [className],
                        )}
                        value={value}
                        onChange={onChange}
                        placeholder={placeholder}
                        type={'text'}
                        id={label}
                        disabled={disabled}
                        onFocus={onFocus}
                        onBlur={onBlur}
                        name={name}
                    />
                )}

                {icon ? (
                    <div className={s.status}>{icon}</div>
                ) : (
                    baseAddon &&
                    isSubmitted && (
                        <div className={s.status}>
                            {error ? (
                                <WarningIcon className={s.warning} />
                            ) : (
                                <CheckIcon className={s.check} />
                            )}
                        </div>
                    )
                )}
            </div>
            {error && error.trim().length > 0 && (
                <span className={s.error}>{error}</span>
            )}
        </div>
    );
};
