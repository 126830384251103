import { createSlice } from '@reduxjs/toolkit';

interface AppState {
    totalStep: number;
    currentStep: number;
}

const initialState: AppState = {
    totalStep: 3,
    currentStep: 1,
};

export const registrationStepperSlice = createSlice({
    name: 'registrationStepper',
    initialState,
    reducers: {
        nextStep: (state) => {
            if (state.currentStep < state.totalStep) {
                state.currentStep += 1;
            }
        },
        prevStep: (state) => {
            if (state.currentStep > 1) {
                state.currentStep -= 1;
            }
        },
        resetRegistrationStepperData: () => initialState,
    },
});

export const { nextStep, prevStep, resetRegistrationStepperData } =
    registrationStepperSlice.actions;

export const { reducer: registrationStepperReducer } = registrationStepperSlice;
