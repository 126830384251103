import { object, string } from 'yup';

export const recoverySchema = object({
    phoneNumber: string()
        .required('Обязательное поле')
        .matches(
            /^(\+7\s?)?((\(\d{3}\)?)|\d{3})([\s.-]?\d{3})([\s.-]?\d{2})([\s.-]?\d{2})$/,
            'Неверный номер телефона',
        ),
});
