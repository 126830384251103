import { object, string } from 'yup';

export const changePasswordSchema = object({
    newPassword: string()
        .required('Обязательное поле')
        .test(function (value) {
            const result = value.match(
                /[^A-Za-z~!?@#$%^&*_\-+()\[\]{}><\/\\|"'.,:\d]+/g,
            );
            return result
                ? this.createError({
                      message: `Пароль содержит недопустимые символы (${result})`,
                      path: 'newPassword',
                  })
                : true;
        })
        .min(8, 'Пароль не соответсвует требованиям')
        .max(64, 'Пароль не соответсвует требованиям')
        .matches(/^(?=.*[a-z])(?=.*[A-Z]).+$/, {
            name: 'words',
            message: 'Пароль не соответсвует требованиям',
        })
        .matches(/^(?=.*\d).+$/, {
            name: 'digit',
            message: 'Пароль не соответсвует требованиям',
        })
        .matches(/(?=.*[~!?@#$%^&*_\-+()\[\]{}><\/\\|"'.,:])/, {
            name: 'special',
            message: 'Пароль не соответсвует требованиям',
        }),
    confirmPassword: string()
        .required('Обязательное поле')
        .test(function (value) {
            return this.parent.newPassword !== value
                ? this.createError({
                      message: `Пароли не совпадают`,
                      path: 'confirmPassword',
                  })
                : true;
        }),
});
