import s from './RegistrationStepper.module.scss';
import { useAppSelector } from '@/shared/lib/hooks/redux';
import classNames from 'classnames';
import React from 'react';
import { PageTitle } from '@/shared/ui/PageTitle/PageTitle';
import { Start, Step2, Step3 } from '@/features/RegistrationForm';

export const RegistrationStepper = () => {
    const { currentStep, totalStep } = useAppSelector(
        (state) => state.registrationStepper,
    );

    const arrayTotalStep = Array.from({ length: totalStep }, (_, i) => i + 1);

    function getSectionComponent() {
        switch (currentStep) {
            case 1:
                return <Start />;
            case 2:
                return <Step2 />;
            case 3:
                return <Step3 />;
        }
    }

    return (
        <div className={s.RegistrationStepper}>
            <PageTitle title="Регистрация | VRStrike" />
            <div className={s.progress}>
                <h2 className={s.stepCount}>
                    Шаг {currentStep}/{totalStep}
                </h2>
                <div className={s.progressBar}>
                    {arrayTotalStep.map((item) => (
                        <span
                            key={item}
                            className={classNames(s.tab, {
                                [s.active]: item <= currentStep,
                            })}
                        />
                    ))}
                </div>
            </div>
            {getSectionComponent()}
        </div>
    );
};
