import s from './Start.module.scss';
import { yupResolver } from '@hookform/resolvers/yup';
import { Input } from '@/shared/ui/Input/Input';
import { StartData } from '../../model/types/start';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@/shared/ui/Button/Button';
import { startSchema } from '../../lib/validationSchema/StartSchema';
import { useStartMutation } from '../../api/registrationApi';
import {
    resetRegistrationData,
    setRegistrationData,
    setRegistrationToken,
    setSendCodeAt,
} from '@/features/RegistrationForm';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { useCallback, useEffect, useState } from 'react';
import { Step1 } from '../Step1/Step1';
import { useRemainingTimer } from '@/shared/lib/hooks/useRemainingTimer';
import { InputTel } from '@/shared/ui/InputTel/InputTel';
import { useFormError } from '@/shared/lib/hooks/useFormError';
import { YandexCaptcha } from '@/shared/ui/YandexCaptcha/YandexCaptcha';
import { useScreenDetector } from '@/shared/lib/hooks/useScreenDetector';
import { phoneNumberFormat } from '@/shared/lib/utils/phoneNumberFormat';
import { RegionSearch } from '@/features/RegionSearch';
import { useLazyGetRegionsQuery } from '@/features/RegionSearch/api/regionsApi';

export const Start = () => {
    const dispatch = useAppDispatch();
    const [triggerStart, { isLoading, isSuccess, error, data }] =
        useStartMutation();

    const [triggerRegionsSearch, { isFetching }] = useLazyGetRegionsQuery();

    const { isMobile } = useScreenDetector();

    const [captcha, setCaptcha] = useState('');
    const [visible, setVisible] = useState(false);
    const [resetCaptcha, setResetCaptcha] = useState(0);
    const {
        data: { phoneNumber, region, firstName, lastName, surname },
        sendCodeAt,
    } = useAppSelector((state) => state.registration);

    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitted, isValid },
        setError,
        watch,
    } = useForm<StartData>({
        mode: 'onTouched',
        resolver: yupResolver(startSchema),
        defaultValues: {
            firstName: firstName || '',
            lastName: lastName || '',
            surname: surname || '',
            region: region || '',
            phoneNumber: phoneNumber || '',
        },
    });

    const timeRemaining = useRemainingTimer(30, sendCodeAt);
    const phoneNumberWatch = watch('phoneNumber');

    const isDirtyAfterSend: boolean =
        firstName !== watch('firstName') ||
        lastName !== watch('lastName') ||
        surname !== watch('surname') ||
        region !== (watch('region').length > 0 ? watch('region') : '-') ||
        phoneNumber !== phoneNumberWatch;

    const disabledSendButton =
        !captcha ||
        (!!timeRemaining && !isDirtyAfterSend) ||
        isLoading ||
        !isValid ||
        isFetching;

    const handleCaptchaReset = () => {
        setCaptcha('');
        setResetCaptcha((i) => i + 1);
    };

    const onSuccessCaptcha = (token: string) => {
        setCaptcha(token);
    };

    const validateRegion = useCallback(async (value: string) => {
        if (value.length > 0 && value !== '-') {
            const result = await triggerRegionsSearch(value);
            if (result?.data?.regions?.[0]?.split('_')[1] !== value) {
                setError('region', {
                    message: 'Выберите регион из списка',
                });
                return false;
            }
        }
        return true;
    }, []);

    const onSubmit = async (data: StartData) => {
        if (await validateRegion(data.region)) {
            triggerStart({
                phoneNumber: phoneNumberFormat(data.phoneNumber),
                captcha: captcha,
            });
        }
    };

    useEffect(() => {
        dispatch(resetRegistrationData());
    }, []);

    useEffect(() => {
        if (isValid) {
            setVisible(true);
        }
    }, [isValid]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(
                setRegistrationData({
                    firstName: watch('firstName'),
                    lastName: watch('lastName'),
                    surname: watch('surname'),
                    phoneNumber: watch('phoneNumber'),
                    region: watch('region').length > 0 ? watch('region') : '-',
                }),
            );
            dispatch(setSendCodeAt(new Date().getTime()));
            dispatch(setRegistrationToken(data.registrationToken));
        }
    }, [isSuccess]);

    useFormError<{ phoneNumber: string }>(error, setError, handleCaptchaReset);

    return (
        <div className={s.wrapper}>
            <form className={s.inputs} onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="lastName"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Фамилия*'}
                            placeholder={'Введите вашу фамилию'}
                            error={errors.lastName?.message}
                            isSubmitted={isSubmitted}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />
                <Controller
                    name="firstName"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Имя*'}
                            placeholder={'Введите ваше имя'}
                            error={errors.firstName?.message}
                            isSubmitted={isSubmitted}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />
                <Controller
                    name="surname"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <Input
                            value={value}
                            onChange={onChange}
                            label={'Отчество'}
                            placeholder={'Введите ваше отчество'}
                            error={errors.surname?.message}
                            isSubmitted={isSubmitted}
                            onBlur={onBlur}
                            name={name}
                        />
                    )}
                />
                <Controller
                    name="region"
                    control={control}
                    render={({ field: { value, onChange, onBlur, name } }) => (
                        <RegionSearch
                            value={value}
                            onChange={onChange}
                            label={'Регион регистрации'}
                            placeholder={'Введите регион'}
                            error={errors.region?.message}
                            isSubmitted={isSubmitted}
                            onBlur={onBlur}
                            validate={validateRegion}
                            name={name}
                        />
                    )}
                />
                <div className={s.actions}>
                    <Controller
                        name="phoneNumber"
                        control={control}
                        render={({ field: { value, onChange, onBlur } }) => (
                            <InputTel
                                value={value}
                                onChange={onChange}
                                label={'Номер телефона*'}
                                placeholder={'+7 900 000-00-00'}
                                error={errors.phoneNumber?.message}
                                isSubmitted={isSubmitted}
                                baseAddon={false}
                                onBlur={onBlur}
                            />
                        )}
                    />
                    <Button
                        type={'submit'}
                        textSize={'b4'}
                        width={'auto'}
                        className={s.button}
                        disabled={disabledSendButton}
                        size={isMobile ? 'xs' : 'm'}
                        height={isMobile && '47px'}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Отправить код
                    </Button>
                </div>
                {visible && (
                    <YandexCaptcha
                        onSuccess={onSuccessCaptcha}
                        resetCaptcha={resetCaptcha}
                    />
                )}
            </form>

            <Step1 disabled={isDirtyAfterSend} />
        </div>
    );
};
