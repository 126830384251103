import { dateFormat } from '@/shared/lib/utils/dateFormat';

export const getAge = (dateString: string) => {
    const today = new Date();
    const birthDate = new Date(dateFormat(dateString));
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
};
