import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface RegistrationStateData {
    firstName: string;
    lastName: string;
    surname: string;
    region: string;
    phoneNumber: string;
    birthDate: string;
    team: string;
    nickname: string;
    vkLink: string;
}

interface RegistrationState {
    data: RegistrationStateData;
    registrationToken: string;
    sendCodeAt: number;
}

const initialState: RegistrationState = {
    data: {
        phoneNumber: '',
        region: '',
        firstName: '',
        lastName: '',
        surname: '',
        birthDate: '',
        team: '',
        nickname: '',
        vkLink: '',
    },
    registrationToken: null,
    sendCodeAt: null,
};

export const registrationSlice = createSlice({
    name: 'registration',
    initialState,
    reducers: {
        setRegistrationData: (
            state,
            action: PayloadAction<Partial<RegistrationStateData>>,
        ) => {
            state.data = {
                ...state.data,
                ...action.payload,
            };
        },
        resetRegistrationData: () => initialState,
        setRegistrationToken: (state, action: PayloadAction<string>) => {
            state.registrationToken = action.payload;
        },
        setSendCodeAt: (state, action: PayloadAction<number>) => {
            state.sendCodeAt = action.payload;
        },
    },
});

export const {
    setRegistrationData,
    setRegistrationToken,
    setSendCodeAt,
    resetRegistrationData,
} = registrationSlice.actions;

export const { reducer: registrationReducer } = registrationSlice;
