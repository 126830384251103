import s from './Step1.module.scss';
import { Input } from '@/shared/ui/Input/Input';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Step1Data } from '../../model/types/step1';
import { step1Schema } from '../../lib/validationSchema/Step1Schema';
import { useEffect } from 'react';
import { Step1Query, useStep1Mutation } from '../../api/registrationApi';
import { useAppDispatch, useAppSelector } from '@/shared/lib/hooks/redux';
import { setRegistrationToken } from '@/features/RegistrationForm/model/slice/registrationSlice';
import { nextStep } from '@/widgets/RegistrationStepper';
import { useRemainingTimer } from '@/shared/lib/hooks/useRemainingTimer';
import { useFormError } from '@/shared/lib/hooks/useFormError';

interface Step1Props {
    disabled: boolean;
}

export const Step1 = (props: Step1Props) => {
    const { disabled } = props;
    const dispatch = useAppDispatch();
    const [triggerStep1, { isLoading, isSuccess, error, data }] =
        useStep1Mutation();

    const {
        data: { firstName, lastName, surname, region },
        registrationToken,
        sendCodeAt,
    } = useAppSelector((state) => state.registration);

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
        setError,
    } = useForm<Step1Data>({
        resolver: yupResolver(step1Schema),
        defaultValues: {
            code: '',
        },
    });

    const timeRemaining = useRemainingTimer(30, sendCodeAt);

    const onSubmit: SubmitHandler<Step1Data> = (data) => {
        triggerStep1({
            registrationToken,
            fullName: surname
                ? lastName + ' ' + firstName + ' ' + surname
                : lastName + ' ' + firstName,
            region: region,
            code: data.code,
        });
    };

    const codeValue = watch('code');

    useEffect(() => {
        if (codeValue.length === 4 && !codeValue.includes('_')) {
            handleSubmit(onSubmit)();
        }
    }, [codeValue]);

    useEffect(() => {
        if (isSuccess) {
            dispatch(setRegistrationToken(data.registrationToken));
            dispatch(nextStep());
        }
    }, [isSuccess]);

    useFormError<Step1Query>(error, setError);

    return (
        <form
            className={s.Step1}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete={'off'}
        >
            <Controller
                name="code"
                control={control}
                render={({ field: { value, onChange, name } }) => (
                    <Input
                        value={value}
                        onChange={onChange}
                        label={'Код из СМС'}
                        placeholder={'0000'}
                        error={errors.code?.message}
                        disabled={!registrationToken || isLoading || disabled}
                        baseAddon={false}
                        mask={'0000'}
                        name={name}
                    />
                )}
            />
            {registrationToken && timeRemaining > 0 && !disabled && (
                <span className={s.timer}>
                    Код подтверждения можно запросить повторно через:{' '}
                    {timeRemaining} секунд
                </span>
            )}
        </form>
    );
};
